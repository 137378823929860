<template>
  <div class="w-full flex flex-col gap-3 p-0">
    <div>
      <div class="flex gap-4 justify-between">
        <div
          id="cardNumber"
          class="w-full h-full p-3 border border-neutral-80 rounded-sm hover:border-primary-100"
          :style="[
            pickedMethod == 'card'
              ? { opacity: 1, height: 'auto' }
              : { opacity: 0, height: 0, padding: 0 }
          ]"
        />
        <div
          id="cardExpiry"
          class="w-[30%] h-full p-3 border border-neutral-80 rounded-sm hover:border-primary-100"
          :style="[
            pickedMethod == 'card'
              ? { opacity: 1, height: 'auto' }
              : { opacity: 0, height: 0, padding: 0 }
          ]"
        />
        <div
          id="cardCvc"
          class="w-[30%] h-full p-3 border border-neutral-80 rounded-sm hover:border-primary-100"
          :style="[
            pickedMethod == 'card'
              ? { opacity: 1, height: 'auto' }
              : { opacity: 0, height: 0, padding: 0 }
          ]"
        />
      </div>
      <div
        id="methodSepa"
        :class="{ 'my-1': pickedMethod == 'sepa_debit' }"
        :style="[
          pickedMethod == 'sepa_debit'
            ? { opacity: 1, height: 'auto' }
            : { opacity: 0, height: 0, padding: 0 }
        ]"
      />
    </div>
    <InputField
      :style="[
        pickedMethod != ''
          ? { opacity: 1, height: 'auto' }
          : { opacity: 0, height: 0, padding: 0 }
      ]"
      :disabled="disabled"
      :placeholder="
        pickedMethod === 'card'
          ? $t('shared.label.card_holder_name')
          : $t('shared.label.name')
      "
      type="text"
      @inputChange="cardName"
    />
    <InputFieldEmail
      v-if="pickedMethod === 'sepa_debit'"
      :disabled="disabled"
      placeholder="E-mail"
      type="text"
      key-name="receipt_email"
      @inputChange="cardEmail"
    />
    <TextSmall v-if="pickedMethod === 'sepa_debit'">
      {{ $t('shared.text.iban') }}
    </TextSmall>
  </div>
</template>
<script setup>
import TextSmall from '@/components/shared/font/text/TextSmall'
import InputField from '@/components/shared/input/InputField'
import InputFieldEmail from '@/components/shared/input/InputFieldEmail'
import i18n from '@/i18n'
import { onMounted, ref } from 'vue'

const $t = i18n.t

const props = defineProps({
  pickedMethod: String,
  disabled: {
    type: Boolean,
    default: false
  }
})

const cardElement = ref(null)
const cardNumber = ref(null)
const cardExpiry = ref(null)
const cardCvc = ref(null)
const ibanElement = ref(null)
const name = ref(null)
const email = ref(null)
const picked = ref('')

defineExpose({
  cardElement,
  cardNumber,
  cardExpiry,
  cardCvc,
  ibanElement,
  name,
  email
})

let stripe

const emit = defineEmits(['stripe'])

onMounted(() => {
  stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
  picked.value = props.pickedMethod
  emit('stripe', stripe)

  let elements = stripe.elements({
    locale: 'en'
  })

  const style = {
    base: {
      color: '#454a5b',
      fontWeight: 200,
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      '::placeholder': {
        color: '#454a5b'
      },
      ':-webkit-autofill': {
        color: '#454a5b'
      }
    },
    invalid: {
      iconColor: '#ff000',
      color: '#ff000'
    }
  }

  // CARD
  cardNumber.value = elements.create('cardNumber', {
    style,
    disabled: props.disabled,
    placeholder: 'Card number'
  })
  cardNumber.value.mount('#cardNumber')

  cardExpiry.value = elements.create('cardExpiry', {
    style,
    disabled: props.disabled
  })
  cardExpiry.value.mount('#cardExpiry')

  cardCvc.value = elements.create('cardCvc', {
    style,
    disabled: props.disabled
  })
  cardCvc.value.mount('#cardCvc')

  // SEPA
  ibanElement.value = elements.create('iban', {
    style,
    disabled: props.disabled,
    supportedCountries: ['SEPA'],
    placeholderCountry: 'DK'
  })
  ibanElement.value.mount('#methodSepa')
})

function cardName(input) {
  name.value = input.value
}
function cardEmail(input) {
  email.value = input.value
}
</script>
