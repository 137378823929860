<template>
  <div>
    <div
      class="w-full min-w-max bg-neutral-80 grid grid-rows-1 gap-x-4 mt-4 text-black py-3 px-4 rounded-t"
      :class="{
        'grid grid-cols-[minmax(150px,_1fr)_150px_150px_minmax(100px,_1fr)_250px]':
          documentsTable,
        'grid grid-cols-[minmax(100px,_1fr)_150px_minmax(100px,_1fr)_250px]':
          templatesTable
      }"
    >
      <TextTiny class="w-[150px]">{{ $t('shared.label.name') }}:</TextTiny>
      <TextTiny>{{ $t('shared.label.created_by') }}:</TextTiny>
      <TextTiny>{{ $t('shared.labels.created_at') }}:</TextTiny>
      <Tooltip
        v-if="documentsTable"
        grey-theme
        :description="$t('shared.tooltip.documents.word_count')"
        class="z-[100]"
      >
        <TextTiny>{{ $t('shared.label.word_count') }}:</TextTiny>
      </Tooltip>
    </div>
  </div>
</template>
<script setup>
import TextTiny from '@/components/shared/font/text/TextTiny'
import Tooltip from '@/components/shared/tooltip/Tooltip'
import i18n from '@/i18n'
const $t = i18n.t
const props = defineProps({
  documentsTable: {
    type: Boolean,
    default: false
  },
  templatesTable: {
    type: Boolean,
    default: false
  }
})
</script>
