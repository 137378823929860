<template>
  <div
    id="navigation"
    @mouseover="showSubnavToggle"
    @mouseleave="hideSubnavToggle"
  >
    <Navbar id="navbar" :expanded="expanded" />
    <Subnav
      id="subnav"
      class="hidden desktop:z-[1] desktop:col-[2_/_1] desktop:row-start-1 desktop:row-end-[-1] desktop:block desktop:bg-white desktop:shadow-[0px_0px_1px_#15174a19] desktop:h-screen"
      :class="{
        'subnav--active desktop:!col-[2_/_3]': showSubNavigationColumn
      }"
    />
    <div
      v-if="
        baseRoute != 'contentGenerationV2' &&
        baseRoute != 'clientPhraseConnector'
      "
      class="group cursor-pointer absolute z-[99] h-[22px] w-[22px] rounded-[50%] top-[50px] left-[50px] bg-white shadow hidden hover:bg-neutral-50"
      :class="{
        '!left-[238px]': expanded,
        '!-right-2.5 !left-[238px]': showNav,
        '!block': showToggleButton
      }"
      @click="onClick"
    >
      <i
        class="fas fa-chevron-left text-xs absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-180 text-neutral-200 z-[1] group-hover:text-neutral-200"
        :class="{
          '-translate-x-1/2 -translate-y-1/2 !rotate-0': showNav
        }"
      />
    </div>
  </div>
</template>
<script setup>
import Navbar from '@/components/navbar/Navbar'
import Subnav from '@/components/navbar/Subnav'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  expanded: Boolean
})

const onToggle = ref(true)
const showToggleButton = ref(false)

const showNav = computed(() => store.state.navigation.showNav)
const baseRoute = computed(() => store.state.navigation.baseRoute)
const showSubnav = computed(() => store.getters['navigation/showSubnav'])
const showSubNavigationColumn = computed(
  () => showNav.value && showSubnav.value
)

const showSubNavigation = (payload) =>
  store.commit('navigation/showSubNavigation', payload)

function onClick() {
  const toggle = (onToggle.value = !showNav.value)
  showSubNavigation(toggle)
}
function showSubnavToggle() {
  showToggleButton.value = true
}
function hideSubnavToggle() {
  showToggleButton.value = false
}
</script>
